<template>
  <div v-if="controllerLoad" my-page>
    <PageHeader :root-path="rootPath" :hasNotification="hasNotification" @openNoti="openNotification" />
    <div class="page-content">
      <router-view :structure="structure" :load="load" />
    </div>
    <GpFooter />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { toFullDateT } from '@/utils/dateTimeUtil';
import { isArray } from 'lodash';
import PageHeader from '@/views/components/gg-pass/PageHeader.vue';
import GpMessageModal from '@/views/components/gg-pass/modal/GpMessageModal.vue';
import { excludeSkipList } from '@/constants/model/my-page';
import GpFooter from '@/views/components/gg-pass/GpFooter.vue';

export default {
  name: 'MyPage',
  components: { GpFooter, PageHeader },
  data() {
    return {
      tokenInterval: null,
      rootPath: 'MyPage',
      isReady: false,
      hasNotification: false,
      structure: {
        action: null,
        config: null,
        current: null,

        model: null,
        title: null,
        desc: null,
        back: null,
        result: null,
        backRoute: null,
        showHistory: false,
        buttonName: 'ok',
        redirectUri: null,
        error: null,
        errorDesc: null,
        errorMsg: null,

        params: null,

        emailModel: null,
        mobilModel: null,

        verifyType: null,
        limitTime: 0,

        reFetch: false,

        onBoardingStepLength: 0,
        isSelfExclusion: false,
        exclusionEndDate: null,
      },
      load: false,
      controllerLoad: false,
    };
  },
  computed: {
    ggpass: state('env', 'ggpass'),
    npOnly: state('env', 'npOnly'),
    brand: state('env', 'brand'),
    theme: state('env', 'theme'),
    site: state('env', 'site'),
    siteInfo: state('env', 'siteInfo'),
    env: state('env', 'env'),
    selectedLang: state('query', 'selectedLang'),
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    // useOnboardingSite() {
    //   return useOnBoardingList.includes(this.site);
    // },
    excludeSkipSite() {
      return excludeSkipList.includes(this.site);
    }
  },
  watch: {
    '$route': 'updateRoute'
  },
  methods: {
    updateRoute() {
      this.$store.commit('env/setTheme', 'white');
    },
    async initialize() {
      this.$store.commit('env/setTheme', 'white');
      if(this.isGgpass){
        await this.$myinfo.setOnepassInfo();
      }
      const result = await this.$myinfo.currencyInitialize();
      if (!result) return;
      if (this.excludeSkipSite) await this.setSelfExclusion();

      await this.setNotification();
      // await this.ggpassTokenRefresh();

      this.load = true;
    },

    openNotification() {
      this.$modal(GpMessageModal, { title: this.$t('notificationBoard'), 'message': this.notification });
    },
    async setNotification() {
      const result = await this.$services.notice.getNotification();
      const { IsShowAgentNotification, IsUpdatedNotification, AgentNotification } = result;
      this.notification = AgentNotification;
      this.hasNotification = IsShowAgentNotification;
      if (AgentNotification && IsUpdatedNotification) {
        this.openNotification();
      }

      return true;
    },

    async setSelfExclusion() {
      const r = await this.$myinfo.getSelfExclusion();
      const isSelfExclusion = r.CurrentSelfExclusionPeriodType.toLowerCase() !== 'none';
      if (!isSelfExclusion) return;
      const locale = this.$i18n('locale');
      this.structure.isSelfExclusion = isSelfExclusion;
      this.structure.exclusionEndDate = toFullDateT(r.ExclusionEndAt, locale);
    },
  },
  async mounted() {
    this.$store.commit('env/setTheme', 'white');
    await sleep(500);
    this.controllerLoad = true;

    await this.$myinfo.setScope(this);
    await this.initialize();
  },

  beforeDestroy() {
    this.$store.commit('env/setTheme', 'dark');
    clearInterval(this.tokenInterval);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-page] { .rel(); .w(100%); .min-h(var(--innerHeight)); .flex(); .flex-dc(); .bgc(@gp-bgc-light-gray);
  .page-content { .flex(); .flex-dc(); .p(0, 0, 30, 0); flex: 1; .max-w(720); .w(100%); margin: auto; }
  footer { .p(16, 20); .bgc(@c-b01); .c(@c-b07); .fs(12, 16); .lb(0, 0); .w(100%); }
}
[drop-list] { box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;
  > .list-holder > .list-box { border: none; .pl(0); .pr(0);
    > .item {
      &:hover {.bgc(#F3FCF5); .c(black);}
      &.active {background: #E6F3E8; .c(black);}
    }
  }
  >.list-wrapper {
    > .list-holder > .list-box { border: none; .pl(0); .pr(0); .br(8); .mt(3);
      >.none-data{.c(black); .fs(14); .p(10, 16, 10, 16); line-height: 16px; font-weight: 400;}
      [drop-tree-list-item]{
        > .item { .c(black); border-top:none; .p(10, 16, 10, 16); .fs(14); line-height: 16px; font-weight: 400;
          &:hover {.bgc(#F3FCF5); .c(black);}
          &.active {background: #E6F3E8; .c(black);}
        }
      }
    }
  }
}

</style>
